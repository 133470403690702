import React from "react"
import Container from "@ecom/ui/components/Container"
import { Img } from "./Img"
import * as styles from "./cardBenefits.module.scss"

type CardBenefitsProps = {
  orderNum?: string
}

const CardBenefits = ({ orderNum }: CardBenefitsProps) => (
  <section className={styles.rate2} data-exclude={orderNum}>
    <Container className={styles.root}>
      <div className={styles.leftSide}>
        <Img alt="Карта Халва" className={styles.img} />
      </div>
      <div className={styles.rightSide}>
        <h2 className={styles.head1}>
          До +3% годовых к вкладу с Халвой и подпиской «Халва.Десятка»
        </h2>
        <p className={styles.desc1}>
          +3% годовых добавляется к ставке вклада, открытого на 24 или 36 месяцев (по другим срокам
          +1%).
        </p>
      </div>
    </Container>
  </section>
)

export default CardBenefits
